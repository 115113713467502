import { Flex } from '@chakra-ui/react';
import { APP_ROUTES, ModuleInfo, ROUTES_WITH_NO_NAV } from 'constants/app-constants';
import { useEffect, useState } from 'react';
import { matchRoutes, useLocation } from 'react-router-dom';

export const AppHeader = () => {
  const [activeModule, setActiveModule] = useState<ModuleInfo | undefined>({
    name: '',
    icon: null,
    href: '',
    label: '',
  });
  const location = useLocation();
  const pathname = location.pathname;
  const allRoutes = [...ROUTES_WITH_NO_NAV, ...APP_ROUTES];

  useEffect(() => {
    setActiveModule(allRoutes[0]);
  }, []);

  useEffect(() => {
    const module = allRoutes.find(({ href }) => {
      const routes = [{ path: href }];
      return !!matchRoutes(routes, location);
    });

    setActiveModule(module);
  }, [pathname]);

  return (
    <Flex height={'4rem'} py={4} justify={'space-between'} alignItems="center">
      {activeModule?.header ? <activeModule.header /> : ''}
    </Flex>
  );
};

export default AppHeader;
