import { UseToastOptions } from '@chakra-ui/toast';
import useToast from 'component-library/hooks/useToast';
import React, { createContext, ReactElement, ReactNode, useContext } from 'react';

type NotificationProviderType = {
  notify: (type: 'success' | 'error' | 'warning' | 'info', message: string, options?: UseToastOptions) => void;
};

const defaultContext = {} as NotificationProviderType;

const NotificationContext = createContext<NotificationProviderType>(defaultContext);

export const useNotification = (): NotificationProviderType => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within an ErrorProvider');
  }
  return context;
};

export const NotificationProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const toast = useToast();

  const notify = (type: 'success' | 'error' | 'warning' | 'info', message: string, options?: UseToastOptions) => {
    toast({
      title: type.charAt(0).toUpperCase() + type.slice(1),
      description: message,
      status: type,
      duration: 5000,
      isClosable: true,
      ...options,
    });
  };

  return <NotificationContext.Provider value={{ notify }}>{children}</NotificationContext.Provider>;
};
