import { Avatar, Flex, Menu, MenuItem, MenuList } from '@chakra-ui/react';
import { supabase } from 'auth/supabase-client';
import MenuButton from 'component-library/components/Menu/MenuButton';
import { storeJWT } from 'cookie-handler';
import { FiLogOut } from 'react-icons/fi';
import { MdKeyboardArrowDown } from 'react-icons/md';

export const Profile = () => {
  const logoutFunction = async () => {
    localStorage.clear();
    storeJWT('');
    const { error } = await supabase.auth.signOut();
    console.log(error);
  };

  return (
    <Menu placement="top">
      <MenuButton color="secondary" variant={'primary'} leftIcon={<MdKeyboardArrowDown />}>
        <Flex gap={2}>
          <Avatar size="xs" src={'Name' || ''} />
        </Flex>
      </MenuButton>
      <MenuList>
        <MenuItem icon={<FiLogOut />} onClick={logoutFunction}>
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
