import {
  Card,
  CardBody,
  Divider,
  Grid,
  GridItem,
  Heading,
  HStack,
  IconButton,
  SimpleGrid,
  Stack,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getAllInvestors } from 'apis/investors';
import Text from 'component-library/components/Text';
import { FiDownload } from 'react-icons/fi';
import { useParams } from 'react-router-dom';

const InvestorProfile = () => {
  const { profileId = '' } = useParams();
  // const navigate = useNavigate();

  const { isLoading, isError, data } = useQuery({
    queryKey: ['investor', profileId],
    queryFn: async () => {
      const { data } = await getAllInvestors();

      if (data && Array.isArray(data)) {
        return data.find(deck => deck.id === parseInt(profileId));
      }

      return data || [];
    },
  });

  if (isLoading) {
    return '...Loading';
  }

  if (isError) {
    return '...Error Occurred';
  }

  console.log(data);
  const { name, sectors, profile } = data;

  return (
    <Stack>
      <Heading size="md">{name}</Heading>
      <HStack gap={4} justifyContent={'space-between'}>
        <Text>{sectors}</Text>
      </HStack>
      <Divider />

      <Grid h="100%" templateColumns="repeat(8, 1fr)" gap={10}>
        <GridItem colSpan={{ sm: 8, md: 6 }}>
          <VStack gap={2} alignItems={'start'}></VStack>
          <VStack w="100%" alignItems={'start'} gap={10}>
            <Text fontSize={16} fontWeight={'bold'}>
              Description
            </Text>
            <Text>
              {profile.Stage}-{profile.Ticket}
            </Text>
            <Text fontSize={20} fontWeight={'600'}>
              How to Apply?
            </Text>
            <SimpleGrid justifyContent={'space-between'} columns={{ sm: 1, md: 3 }} gap={10}>
              <VStack alignItems={'center'} p={[10]} border={'1px solid #eee'}>
                <IconButton rounded={'full'} shadow={'xl'} variant={'ghost'} aria-label="info-button" size={'lg'}>
                  <FiDownload fontSize={'24px'} color="#000" />
                </IconButton>
                <Text fontWeight={'500'}>Study what’s right for you</Text>
                <Text>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                </Text>
              </VStack>{' '}
              <VStack alignItems={'center'} p={[10]} border={'1px solid #eee'}>
                <IconButton rounded={'full'} shadow={'xl'} variant={'ghost'} aria-label="info-button" size={'lg'}>
                  <FiDownload fontSize={'24px'} color="#000" />
                </IconButton>
                <Text fontWeight={'500'}>Take the readiness test</Text>
                <Text>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                </Text>
              </VStack>{' '}
              <VStack alignItems={'center'} p={[10]} border={'1px solid #eee'}>
                <IconButton rounded={'full'} shadow={'xl'} variant={'ghost'} aria-label="info-button" size={'lg'}>
                  <FiDownload fontSize={'24px'} color="#000" />
                </IconButton>
                <Text fontWeight={'500'}>Apply from GRC</Text>
                <Text>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                </Text>
              </VStack>
            </SimpleGrid>
          </VStack>
          {/* <Card w="100%" shadow={'xl'} rounded={'lg'}>
            <CardBody>
              <Stack direction={{ sm: 'column', md: 'row' }}>
                <VStack gap={2} alignItems={'start'}>
                  <Text fontSize={18} fontWeight={'500'} color={'primary.500'}>
                    Hassle free applications
                  </Text>
                  <Text fontSize={12} color="gray.500">
                    Click to build the profile
                  </Text>
                  <Text fontSize={12} fontWeight={'400'}>
                    Core competencies: Financial acumen, market analysis, strategic thinking, problem-solving. Cultural
                    fit: Teamwork, communication, adaptability, risk tolerance. Time management: Ability to work under
                    pressure and meet deadlines.
                  </Text>
                </VStack>
                <Button
                  w="-webkit-fill-available"
                  onClick={() => {
                    navigate('/profile');
                  }}
                >
                  Complete profile building
                </Button>
              </Stack>
            </CardBody>
          </Card> */}
        </GridItem>
        <GridItem colSpan={{ sm: 8, md: 2 }}>
          <Card h="100%" p={0} bg="grey.100" rounded={'lg'}>
            <CardBody>
              <Text fontSize={20} fontWeight={500}>
                Growthpal checklist
              </Text>
              <Divider my={4} />
              <VStack>
                {[1].map(el => {
                  return (
                    <Card w="100%" p={0} shadow={'lg'} rounded={'lg'}>
                      <CardBody>
                        <Text fontSize={12} fontWeight={'500'}>
                          Readiness test
                        </Text>
                        <Text fontSize={12} fontWeight={'400'}>
                          Why it is important? consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                          et dolore magna aliqua. Proin sed libero enim sed faucibus. Aliquam nulla facilisi cras
                          fermentum odio eu. Orci eu lobortis elementum nibh
                        </Text>
                        {el}
                      </CardBody>
                    </Card>
                  );
                })}
              </VStack>
            </CardBody>
          </Card>
        </GridItem>
      </Grid>
    </Stack>
  );
};

export default InvestorProfile;
