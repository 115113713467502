import Hashids from 'hashids';
import { useEffect } from 'react';

declare global {
  interface Window {
    analytics: any;
  }
}

const ENCODE_KEY = '0crjGSYPU5c6IQ2lSD2gUecCpQLAFf92';

let analytics: any;
let encoder: Hashids;

const useTracking = () => {
  useEffect(() => {
    analytics = window.analytics;
    encoder = new Hashids(ENCODE_KEY);
  }, []);

  const isAnalyticsLibLoaded = (): boolean => {
    if (!analytics) {
      console.log('analytics not loaded properly');
      return false;
    }
    if (import.meta.env.VITE_ENABLE_INSTRUMENTATION) {
      return analytics;
    }
    return false;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const track = (eventName: string, properties?: { [key: string]: any }): void => {
    if (!isAnalyticsLibLoaded()) {
      return;
    }
    analytics.track(eventName, properties);
  };

  const pageLoad = (): void => {
    if (!isAnalyticsLibLoaded()) {
      return;
    }

    analytics.page();
  };

  const identifyUser = (user: { email: string; id: string }): void => {
    if (!isAnalyticsLibLoaded()) {
      return;
    }
    analytics.identify(user.id, { email: user.email });
    // Fix to merge previous userId with new userId
    analytics.alias({ previousId: encoder.encode(user.id), userId: user.id });
  };

  return { track, pageLoad, identifyUser };
};

export default useTracking;
