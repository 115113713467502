import { HomeHeaderToolbar } from 'app-header-toolbar/dashboard-header';
import DealsHeaderToolbar from 'app-header-toolbar/deals-header';
import NewsHeaderToolbar from 'app-header-toolbar/news-header';
import React from 'react';
import { IoHomeOutline, IoNewspaperOutline } from 'react-icons/io5';
import { MdOutlineDashboard } from 'react-icons/md';

export interface ModuleInfo {
  name: string;
  icon: any;
  href: string;
  label: React.ReactNode;
  header?: React.ComponentType<any>;
  isBottomToolbar?: boolean;
  pageTitle?: string;
}

export const ROUTES_WITH_NO_NAV: ModuleInfo[] = [];

export const APP_ROUTES: ModuleInfo[] = [
  {
    name: 'Home',
    icon: IoHomeOutline,
    href: '/home',
    label: 'Home',
    pageTitle: 'GrowthPal | Home',
    header: HomeHeaderToolbar,
  },
  {
    name: 'Deals',
    icon: MdOutlineDashboard,
    href: '/deals',
    label: 'Deals',
    pageTitle: 'GrowthPal | Deals',
    header: DealsHeaderToolbar,
  },
  {
    name: 'News',
    icon: IoNewspaperOutline,
    href: '/news',
    label: 'News',
    pageTitle: 'GrowthPal | News',
    header: NewsHeaderToolbar,
  },
];
