import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
  field: {
    border: '1px solid rgba(207, 208, 216, 0.60)',
    background: 'var(--gray-0, #FFF)',
  },
  element: {
    color: 'white',
  },
});

export const inputTheme = defineMultiStyleConfig({ baseStyle });
