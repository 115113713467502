import {
  Avatar,
  Box,
  Button,
  Card,
  CardBody,
  chakra,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  IconButton,
  Link,
  SimpleGrid,
  Stack,
  Stat,
  StatHelpText,
  StatNumber,
  useRadio,
  useRadioGroup,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { getCompany, getCompanyDetails } from 'apis/company';
import { getAllDecks } from 'apis/deck';
import Text from 'component-library/components/Text';
import { BsArrowRight, BsLink } from 'react-icons/bs';
import { MdOutlineStickyNote2 } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { Bar, BarChart, Tooltip, XAxis, YAxis } from 'recharts';

function Tab(props: { [x: string]: any; text: string }) {
  const { text, ...radioProps } = props;
  const { state, getInputProps, getRadioProps, htmlProps, getLabelProps } = useRadio(radioProps);

  return (
    <chakra.label {...htmlProps} cursor="pointer">
      <input {...getInputProps({})} hidden />
      <Box minW="5rem" {...getRadioProps()} p={1} rounded="full">
        <Text {...getLabelProps()}>{text}</Text>
        <Box w="50%" bg={state.isChecked ? '#7681C0' : 'white'} h={1} rounded="full" alignSelf={'center'} />
      </Box>
    </chakra.label>
  );
}

const StartupProfile = () => {
  const navigate = useNavigate();

  const tabs = [
    { name: 'Profile', value: 'Profile' },
    // { name: 'Company', value: 'Company' },
  ];

  const { value, getRadioProps, getRootProps } = useRadioGroup({
    defaultValue: 'Profile',
  });

  const {
    isLoading,
    isError,
    data: decks = [],
  } = useQuery({
    queryKey: ['decks'],
    queryFn: async () => {
      const res = await getAllDecks();

      return res?.data || [];
    },
  });

  const {
    isLoading: companyIsLoading,
    isError: companyError,
    data: companyDetails,
  } = useQuery({
    queryKey: ['company', 'companyDetails'],
    queryFn: async () => {
      const { data: companyData } = await getCompany();
      const { data: companyDetails } = await getCompanyDetails(companyData[0].profile.remoteProfile.id);

      return {
        company: companyData[0],
        details: companyDetails,
      };
    },
  });

  const { company, details } = companyDetails || {};

  if (isLoading || companyIsLoading) {
    return '...Loading';
  }

  if (isError || companyError) {
    return '...Error Occurred';
  }

  const stats = [
    {
      value: details.empCount,
      label: 'Employee Count',
    },
    {
      value: `${details.empGrowthPercentage || 0} %`,
      label: 'Employee Growth Percentage',
    },
    {
      value: `${details.techTeamPercentage || 0} %`,
      label: 'Tech Team Percentage',
    },
    // {
    //   value: `${details.salesTeamSize || 0}`,
    //   label: 'Sales Team Size',
    // },
    {
      completeProfileButton: true,
    },
  ];

  const downloadPublicProfile = (filename: string, text: string) => {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const pickPublicFields = (object: object) => {
    const picked = (({
      companyName,
      description,
      linkedin,
      empCount,
      foundedYear,
      linkedinDescription,
      specialities,
      website,
      country,
      city,
      state,
      sectors,
      product,
    }: any) => ({
      companyName,
      description,
      linkedin,
      empCount,
      foundedYear,
      linkedinDescription,
      specialities,
      website,
      country,
      city,
      state,
      sectors,
      product,
    }))(object);

    return picked;
  };

  const activeProfile = (
    <Card p={0} bg="#EBEBEB" rounded={'lg'}>
      <CardBody>
        <Grid templateColumns="repeat(2, 1fr)" gap={10} mt={4}>
          <GridItem colSpan={{ base: 2, md: 1 }}>
            <HStack gap={2}>
              <Avatar />
              <VStack alignItems={'left'}>
                <Link
                  color={'initial'}
                  fontSize={16}
                  fontWeight={'bold'}
                  isExternal={true}
                  href={details.linkedin}
                  target="_blank"
                >
                  {details.companyName || company?.name || company?.email}
                </Link>
                <Text>{details.sectors?.join(', ')}</Text>
              </VStack>
            </HStack>
            <Grid templateColumns="repeat(4, 1fr)" rounded={'lg'} mt={10} p={4} bg="#000" gap={4}>
              <GridItem colSpan={3}>
                <Box>
                  <Text color="#fff" fontWeight={500} fontSize={18}>
                    Want to share your company profile?
                  </Text>
                  <Text mt={4} color="#fff">
                    We understand the problem, some information should be hidden or seen, Growthpal gives you ‘masked’
                    profile, to share according to your preference
                  </Text>
                </Box>
              </GridItem>
              <GridItem colSpan={1}>
                <Button
                  bg="transparent"
                  size={'lg'}
                  onClick={() => {
                    downloadPublicProfile(
                      `${details.companyName}_public_profile.txt`,
                      JSON.stringify(pickPublicFields(details), null, 2)
                    );
                  }}
                  height={'100px'}
                >
                  <VStack p={4}>
                    <BsLink fontSize={20} fontWeight={500} />
                    <Text fontSize={16} fontWeight={'bold'} color={'inherit'}>
                      Download public profile
                    </Text>
                  </VStack>
                </Button>
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem colSpan={{ base: 2, md: 1 }}>
            <Flex direction={'column'} w="100%" gap={2} alignItems={'flex-start'} justifyContent={'space-between'}>
              <Grid h="100%" templateColumns="repeat(3, 1fr)" gap={10}>
                {details.empSplitLocation && (
                  <GridItem minH={'150px'} rowSpan={1} colSpan={2}>
                    <VStack h="100%" border={'1px solid #F2F5F7'} p={[4, 2]} bgColor={'#fff'} rounded={'lg'}>
                      <Text fontSize={16} fontWeight={600}>
                        Employee Numbers By Location
                      </Text>
                      <BarChart
                        barGap={0}
                        barSize={40}
                        width={400}
                        height={200}
                        data={details.empSplitLocation}
                        margin={{
                          top: 5,
                          right: 10,
                          left: 0,
                          bottom: 5,
                        }}
                      >
                        <XAxis
                          dataKey="name"
                          includeHidden
                          style={{
                            fontFamily: 'inherit',
                            fontWeight: 400,
                            fontSize: '12px',
                          }}
                        />
                        <YAxis
                          style={{
                            fontFamily: 'inherit',
                            fontWeight: 400,
                            fontSize: '12px',
                          }}
                        />
                        <Tooltip
                          labelStyle={{
                            color: 'initial',
                          }}
                          itemStyle={{
                            color: 'initial',
                            textTransform: 'capitalize',
                          }}
                        />
                        <Bar barSize={40} dataKey="count" fill="#7681C0" background={false} />
                      </BarChart>
                    </VStack>
                  </GridItem>
                )}

                {stats.map(({ value, label, completeProfileButton }) => {
                  return (
                    <GridItem rowSpan={1}>
                      {completeProfileButton ? (
                        <Button
                          size={'lg'}
                          onClick={() => {
                            navigate('/profile');
                          }}
                          height={'100px'}
                          rightIcon={<BsArrowRight fontSize={20} fontWeight={500} />}
                        >
                          <Text
                            fontSize={16}
                            fontWeight={'bold'}
                            color={'inherit'}
                            style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
                          >
                            Complete your profile
                          </Text>
                        </Button>
                      ) : (
                        <Stat
                          minW="fit-content"
                          border={'1px solid #F2F5F7'}
                          p={[4, 2]}
                          bgColor={'#fff'}
                          rounded={'lg'}
                        >
                          <StatNumber color={'initial'}>{value}</StatNumber>
                          <StatHelpText color={'initial'}>{label}</StatHelpText>
                        </Stat>
                      )}
                    </GridItem>
                  );
                })}
              </Grid>
            </Flex>
          </GridItem>
        </Grid>
      </CardBody>
    </Card>
  );

  return (
    <Stack>
      <Heading size="md">Account profile</Heading>
      <HStack gap={4} justifyContent={'space-between'}>
        {/* <Text>Company overview, switch to ‘Company’ for detailed form & questionnaire</Text> */}
        <Stack {...getRootProps()}>
          <HStack>
            {tabs.map(tab => {
              return <Tab key={tab.name} text={tab.value} {...getRadioProps({ value: tab.name })} />;
            })}
          </HStack>
        </Stack>
      </HStack>

      {value === 'Profile' ? (
        <SimpleGrid columns={[1]} gap={4} p={4}>
          {activeProfile}
          <Heading size="sm">Your decks</Heading>
          <Box w="100%">
            <SimpleGrid columns={3} spacing={10}>
              {decks?.map((deck: any) => {
                return (
                  <HStack
                    cursor="pointer"
                    gap={2}
                    p={2}
                    border="1px solid #D2D6DB"
                    rounded={8}
                    onClick={() => {
                      navigate(`/deck-analysis/${deck.id}`);
                    }}
                  >
                    <IconButton aria-label="as" isRound variant={'outlined'} color="primary">
                      <MdOutlineStickyNote2 color="#000" />
                    </IconButton>
                    <div>
                      <Text w="300px" isTruncated={true} fontSize={'14px'}>
                        {deck.name || 'VC deck.pdf'}
                      </Text>
                      <Text fontSize={'12px'} color="#9DA4AE">
                        {deck.status}
                      </Text>
                    </div>
                  </HStack>
                );
              })}
            </SimpleGrid>
          </Box>
        </SimpleGrid>
      ) : (
        <Text>WIP</Text>
      )}
    </Stack>
  );
};

export default StartupProfile;
