import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tabsAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  tab: {
    color: '#262B47',
    _active: {
      bg: '#FFFFFF',
    },
    _selected: {
      borderBottomWidth: '1px',
      color: '#4285F4',
      fontWeight: 500,
    },
    whiteSpace: 'nowrap',
  },
  tablist: {
    gap: '0px',
  },
});

export const tabTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    line: {
      tablist: { borderBottom: '1px solid ', borderColor: '#EFEFF3' },
    },
  },
});
