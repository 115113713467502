export const globalStyles = {
  styles: {
    global: () => ({
      body: {
        fontFamily: "'Poppins', sans-serif",
        fontSize: '16px',
      },
      html: {
        fontFamily: "'Poppins', sans-serif",
        fontSize: '16px',
      },
    }),
  },
};
