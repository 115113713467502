// import { httpClientIntegration } from '@sentry/integrations';
// import * as Sentry from '@sentry/react';
import App from 'app/app';
import ReactDOM from 'react-dom/client';
// import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { registerSW } from 'virtual:pwa-register';

// const env = import.meta.env.VITE_ENVIRONMENT ?? 'local';
// const sampleRate = env === 'prod' ? 0.3 : 0.1;
const startTime = Date.now(); // Record the start time when the script begins execution
const waitTime = 1000 * 3 * 60; // 3 minutes

const updateSW = registerSW({
  onNeedRefresh() {
    const endTime = Date.now(); // Get the current time
    const elapsed = endTime - startTime; // Calculate the elapsed time since the script started
    if (elapsed < waitTime) {
      updateSW();
      return;
    }
    if (confirm('New content available. Reload?')) {
      updateSW(true);
    }
  },
});

// if (env === 'prod') {
//   Sentry.init({
//     environment: env,
//     dsn: 'sentry_url',
//     integrations: [
//       Sentry.browserTracingIntegration(),
//       // See docs for support of different versions of variation of react router
//       // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
//       Sentry.reactRouterV6BrowserTracingIntegration({
//         useEffect: React.useEffect,
//         useLocation,
//         useNavigationType,
//         createRoutesFromChildren,
//         matchRoutes,
//       }),
//       Sentry.replayIntegration(),
//       httpClientIntegration(),
//     ],
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     tracesSampleRate: sampleRate,
//     replaysSessionSampleRate: 0.1,
//     replaysOnErrorSampleRate: 1.0,
//   });
// }

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);
