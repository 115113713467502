import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const fetchOrgs = createAsyncThunk('orgs/fetchOrgs', async () => {
  // make api call
  return {};
});

const testSlice = createSlice({
  name: 'test',
  initialState: {
    data: [],
    loading: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchOrgs.pending, state => {
        state.loading = 'loading';
      })
      .addCase(fetchOrgs.fulfilled, state => {
        state.loading = 'succeeded';
      })
      .addCase(fetchOrgs.rejected, state => {
        state.loading = 'failed';
      });
  },
});

export default testSlice.reducer;
