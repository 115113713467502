import { Button, ButtonProps, Flex, Icon } from '@chakra-ui/react';
import { NavLink } from 'component-library/components/nav-link';
import Text from 'component-library/components/Text';
import Tooltip from 'component-library/components/Tooltiptmp/tooltip';
import { APP_ROUTES, ModuleInfo } from 'constants/app-constants';
import { useEffect, useState } from 'react';
import { IconType } from 'react-icons';
import { MdOutlineKeyboardDoubleArrowLeft, MdOutlineKeyboardDoubleArrowRight } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';

type NavItemProps = {
  href: string;
  title: string;
  icon: IconType;
  isExpanded: boolean;
} & ButtonProps;

const NavItem = ({ title, href, icon, isExpanded = true, ...rest }: NavItemProps) => {
  const { children, ...btnProps } = rest;

  return (
    <Tooltip label={isExpanded ? null : title} placement={'right'}>
      <Button
        as={NavLink}
        to={href}
        flexWrap={'nowrap'}
        gap={2}
        variant="nav"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        {...btnProps}
        leftIcon={icon && <Icon boxSize="24px" as={icon} />}
        style={isExpanded ? {} : { width: 'unset' }}
      >
        {isExpanded ? children : null}
      </Button>
    </Tooltip>
  );
};

type SidebarProps = {
  hideToggleBtn?: boolean;
  isExpanded?: boolean;
  onSidebarExpand?: () => void;
  onClose?: () => void;
};
export const Sidebar = ({ hideToggleBtn = true, isExpanded = true, onSidebarExpand, onClose }: SidebarProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const active = APP_ROUTES.find(({ href }: ModuleInfo) => href === pathname);
  const [activeButton, setActiveButton] = useState<string | undefined>(active?.name);

  useEffect(() => {
    const newActive = APP_ROUTES.find(({ href }: ModuleInfo) => href === pathname);
    setActiveButton(newActive?.name);
  }, [pathname]);

  const handleRedirect = (item: ModuleInfo) => {
    navigate(item.href);
    setActiveButton(item.name);
    onClose && onClose();
  };

  return (
    <Flex
      as={'nav'}
      data-onboarding="sidebar"
      boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
      h={'100%'}
      flexDir="column"
      justifyContent="space-between"
      py={'16px'}
      overflow="auto"
    >
      <Flex px="5%" flexDirection="column">
        <Text align={'center'} fontSize={'30px'} color="gray.000" fontWeight="bold" mb={4}>
          G
        </Text>
        <Flex ml={isExpanded ? '10px' : 4} justify={'space-between'} align={'center'} mb={2}>
          {!hideToggleBtn && (
            <Button
              variant={'unstyled'}
              color={'gray.300'}
              onClick={onSidebarExpand}
              style={
                isExpanded
                  ? {
                      left: '1.2rem',
                    }
                  : {
                      background: '#262B47',
                      position: 'absolute',
                      left: '3rem',
                      minWidth: 'unset',
                    }
              }
            >
              {isExpanded ? (
                <MdOutlineKeyboardDoubleArrowLeft size={24} />
              ) : (
                <MdOutlineKeyboardDoubleArrowRight size={24} />
              )}
            </Button>
          )}
        </Flex>
        <Flex gap={2} flexDirection={'column'} justifyContent={'space-between'}>
          {APP_ROUTES.map(
            (link: ModuleInfo) =>
              !link.isBottomToolbar && (
                <NavItem
                  key={link.name}
                  title={link.name}
                  data-onboarding={link.name}
                  isActive={activeButton === link.name}
                  icon={link.icon}
                  onClick={() => handleRedirect(link)}
                  href={link.href}
                  isExpanded={isExpanded}
                >
                  {link.name}
                </NavItem>
              )
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
