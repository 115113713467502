import { Box } from '@chakra-ui/react';
import { getCompany, getCompanyDetails } from 'apis/company';
import { getUser } from 'apis/user';
import React, { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AppLoader from './app-loader';

type AppLoaderWrapperContext = {
  isLoading: boolean;
  isProfileLocked: boolean;
  showLoader: () => void;
  hideLoader: () => void;
  setCompanyLocked: () => void;
  setCompanyUnLocked: () => void;
};

export const AppLoaderWrapperContext = createContext<AppLoaderWrapperContext>({
  isLoading: false,
  isProfileLocked: false,
  showLoader: () => {},
  hideLoader: () => {},
  setCompanyUnLocked: () => {},
  setCompanyLocked: () => {},
});

export const useAppLoaderWrapper = () => useContext(AppLoaderWrapperContext);

export const AppLoaderWrapperProvider = ({ children }: PropsWithChildren) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isProfileLocked, setIsProfileLocked] = useState(false);

  const showLoader = () => setIsLoading(true);
  const hideLoader = () => setIsLoading(false);
  const setCompanyLocked = () => setIsProfileLocked(true);
  const setCompanyUnLocked = () => setIsProfileLocked(false);

  const contextValue = useMemo(
    () => ({
      isLoading,
      isProfileLocked,
      showLoader,
      hideLoader,
      setCompanyLocked,
      setCompanyUnLocked,
    }),
    [isLoading]
  );
  return <AppLoaderWrapperContext.Provider value={contextValue}>{children}</AppLoaderWrapperContext.Provider>;
};

export const AppLoaderWrapper = React.memo(({ children }: PropsWithChildren) => {
  const { isLoading, showLoader, hideLoader, setCompanyLocked, setCompanyUnLocked } = useAppLoaderWrapper();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      showLoader();
      const { data } = await getUser();

      if (data && data['user'] && data['user']['companyId'] === null) {
        navigate('/onboarding/1');
      }
      const { data: companyData } = await getCompany();

      if (companyData[0] && companyData[0].profile?.distributionPartners) {
        setCompanyUnLocked();
      } else {
        setCompanyLocked();
      }
      if (companyData[0].profile) {
        await getCompanyDetails(companyData[0].profile.remoteProfile.id);
      }

      hideLoader();
    };
    fetchData();
  }, []);

  return (
    <>
      <Box visibility={isLoading ? 'hidden' : 'visible'}>{children}</Box>
      <AppLoader pastDelay={isLoading} />
    </>
  );
});
AppLoaderWrapper.displayName = 'AppLoaderWrapper';
