import { ModuleInfo } from 'constants/app-constants';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const updateDocTitle = (title: string) => {
  document.title = title;
};

const useUpdateDocTitle = (pages: ModuleInfo[]) => {
  const location = useLocation();

  useEffect(() => {
    const newActive = pages.find((page: ModuleInfo) => page.href === location.pathname);
    updateDocTitle(newActive?.pageTitle ? newActive?.pageTitle : 'GP');
  }, [location]);
};

export { updateDocTitle };
export default useUpdateDocTitle;
