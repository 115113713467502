import { defineStyleConfig } from '@chakra-ui/react';

export const textStyles = defineStyleConfig({
  baseStyle: {
    fontSize: '14px',
    fontWeight: '400',
    color: '#000000',
  },
  sizes: {
    sm: {
      fontSize: '12px',
    },
    md: {
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
    },
    lg: {
      fontSize: '16px',
      fontWeight: '500',
      lineHeight: '26px',
    },
  },
  variants: {
    primary: {
      color: '#262B47',
    },
    secondary: {
      color: '#3C4059',
    },
  },
  defaultProps: {
    size: 'md',
  },
});
