import { useToast as chakraToast, UseToastOptions } from '@chakra-ui/react';
import ToastContainer from 'component-library/components/Toast/toast';
import React from 'react';

const defaultOptions: UseToastOptions = {
  duration: 2000,
  position: 'top-right',
  isClosable: true,
};

const useToast = (options: UseToastOptions = {}) => {
  if (!options.render) {
    // default toast component
    defaultOptions.render = props => {
      return <ToastContainer {...props} />;
    };
  }
  const toast = chakraToast({
    ...defaultOptions,
    ...options,
  });

  return toast;
};

export default useToast;
