import api from './api';

const getCompany = () => {
  return api.get(`/companies`);
};

const getCompanyDetails = (remoteId: string) => {
  return api.get(`/companies/remote-details`, {
    params: {
      id: remoteId,
    },
  });
};

const updateCompany = (payload: object) => {
  return api.patch(`/companies`, {
    profile: payload,
  });
};

const createCompany = (payload: object) => {
  return api.post(`/companies`, payload);
};

const searchCompany = (q: string) => {
  return api.get(`/companies/search`, {
    params: {
      q: q,
    },
  });
};

export { createCompany, getCompany, getCompanyDetails, searchCompany, updateCompany };
