import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import testSlice from './slices/testSlice';

/**
 * TODO :
 * 2. integrate query lib
 * 3. integrate logger
 * 4. persistReducer
 * 5. handle user via redux
 */
const store = configureStore({
  devTools: import.meta.env.VITE_ENVIRONMENT !== 'prod',
  reducer: {
    test: testSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve types
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
