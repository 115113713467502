import { Flex, HStack } from '@chakra-ui/react';
import Button from 'component-library/components/ButtonTmp/button';
import { NavLink } from 'component-library/components/nav-link';
import Text from 'component-library/components/Text';
import { IoMdNotificationsOutline } from 'react-icons/io';

import { Profile } from './profile';

const NewsHeaderToolbar = () => {
  return (
    <HStack justifyContent={'space-between'} w="100%">
      <Text size={'lg'}>News. Updates. Learnings</Text>
      <Flex gap={2}>
        <Button color="secondary" as={NavLink} to="/home">
          <IoMdNotificationsOutline size="lg" />
        </Button>
        <Profile />
      </Flex>
    </HStack>
  );
};

export default NewsHeaderToolbar;
