import { Box, CloseButton, Flex, Text, UseToastOptions } from '@chakra-ui/react';
import { ToastError, ToastSuccess } from 'components/icons';
import React from 'react';

interface RenderProps extends UseToastOptions {
  /**
   * Function to close the toast
   */
  onClose(): void;
}

const ToastContainer: React.FC<RenderProps> = ({ status, title, description, onClose }) => {
  const backgroundColor = status === 'success' ? '#C6F6D5' : '#FED7D7';

  return (
    <Box
      minWidth={'400px'}
      py={3}
      px={4}
      bg={backgroundColor}
      justifyContent={'space-between'}
      display="flex"
      alignItems="center"
      gap={3}
    >
      <Flex direction={'row'} alignItems="center" gap={'12px'}>
        <Box>{status === 'success' ? <ToastSuccess /> : <ToastError />}</Box>
        <Box>
          <Text fontSize={'16px'} fontWeight={500}>
            {title}
          </Text>
          <Text fontSize={'14px'} fontWeight={400}>
            {description}
          </Text>
        </Box>
      </Flex>
      <CloseButton onClick={onClose} />
    </Box>
  );
};

export default ToastContainer;
