import { Box } from '@chakra-ui/react';
import { SkipNavContent, SkipNavLink } from '@chakra-ui/skip-nav';
import styled from '@emotion/styled';
import { ErrorBoundary, ErrorFallback } from 'components/error-fallback';
import { Sidebar } from 'components/Sidebar';
import { APP_ROUTES } from 'constants/app-constants';
import usePageTracking from 'hooks/usePageTracking';
import useUpdateDocTitle from 'hooks/useUpdateDocTitle';
import { useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useLocation } from 'react-router-dom';

import AppDrawers from './app-drawers';
import AppHeader from './app-header';
import AppPopups from './app-popups';

const App = styled(Box)(() => ({ display: 'flex', height: '100vh' }));
const AppBody = styled(Box)(() => ({ height: '100%', overflow: 'auto' }));
const AppContent = styled(Box)(() => ({ height: 'calc(100% - 4rem)', overflow: 'auto', paddingBottom: '0.5rem' }));

const fallBack = (props: any) => <ErrorFallback {...props} />;

export const AppLayout = (props: any) => {
  const scrollContainer: any = useRef(null);
  const location = useLocation();
  usePageTracking();
  useUpdateDocTitle(APP_ROUTES);

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  useHotkeys('[', () => setIsExpanded(!isExpanded), [isExpanded]);

  useEffect(() => {
    if (scrollContainer?.current) {
      scrollContainer.current.scrollTop = 0;
    }
  }, [location]);

  const sidebarWidth = isExpanded ? '250px' : '4rem';

  return (
    <>
      <SkipNavLink zIndex={9999}>Skip to content</SkipNavLink>
      <App>
        <ErrorBoundary fallback={fallBack}>
          <Box h={'100vh'} w={sidebarWidth}>
            <Sidebar isExpanded={isExpanded} onSidebarExpand={() => setIsExpanded(!isExpanded)} />
          </Box>
          <AppBody px={6} w="100%">
            <SkipNavContent />
            <AppHeader />
            <AppContent ref={scrollContainer}>{props.children}</AppContent>
          </AppBody>
          <AppPopups />
          <AppDrawers />
        </ErrorBoundary>
      </App>
    </>
  );
};

export default AppLayout;
