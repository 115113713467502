import axios, { AxiosError } from 'axios';
import { getToken } from 'cookie-handler';

axios.defaults.baseURL = import.meta.env.VITE_API_URL;
axios.defaults.withCredentials = false;

const api = axios;

/**
 * axios request interceptors
 */
axios.interceptors.request.use(function (config) {
  const accessToken = getToken();
  config.headers.Authorization = `Bearer ${accessToken}`;
  if (import.meta.env.VITE_ENVIRONMENT === 'local') {
    config.headers['ngrok-skip-browser-warning'] = '1234';
  }
  return config;
});

/**
 * axios response interceptors
 */
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export type ApiError = AxiosError;

export default api;
