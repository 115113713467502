/* eslint-disable react-hooks/rules-of-hooks */
// eslint-disable-next-line simple-import-sort/imports
import { Image } from '@chakra-ui/react';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { supabase } from 'auth/supabase-client';
import { NotificationProvider } from 'components/error/notification-context';
import { storeJWT } from 'cookie-handler';
import InvestorProfile from 'pages/InvestorProfile';
import StartupProfile from 'pages/StartupProfile';
import { Suspense, lazy, useEffect, useState } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import logo from '../images/growthpal_logo.svg';
import AppLayout from './app-layout';
import AppLayoutSm from './app-layout.sm';
import { AppLoaderWrapper, AppLoaderWrapperProvider } from './app-loader-wrapper';

const Home = lazy(() => import('pages/Home'));
const News = lazy(() => import('pages/News'));
const Deals = lazy(() => import('pages/Deals'));
const Profile = lazy(() => import('pages/Profile'));
const Onboarding = lazy(() => import('pages/Onboarding'));
const DeckUpload = lazy(() => import('pages/Deck'));
const DeckAnalysis = lazy(() => import('pages/Deck/analysis'));

const withAppLayout = (Module: React.ComponentType<any>) => {
  const matches = true;
  // const [matches, setMatches] = useState(window.matchMedia('(min-width: 768px)').matches);

  // useEffect(() => {
  //   window.matchMedia('(min-width: 768px)').addEventListener(
  //     'change',
  //     debounce(e => setMatches(e.matches), 500)
  //   );
  // }, []);

  const [session, setSession] = useState<any>(null);
  const [loading, seLoading] = useState<boolean>(true);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      seLoading(false);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!session) {
    return (
      <div
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          background: 'linear-gradient(45deg, #21409A 0%, #0C0F13 100%)',
          padding: '50px',
        }}
      >
        <div
          style={{
            minWidth: '300px',
            width: '40vw',
            margin: 'auto',
            backgroundColor: '#fff',
            padding: '50px 100px',
            borderRadius: '20px',
          }}
        >
          <Image margin={'auto'} src={logo} alt="logo" />
          <Auth supabaseClient={supabase} appearance={{ theme: ThemeSupa }} providers={[]} />;
        </div>
      </div>
    );
  }

  session && storeJWT(session.access_token || '');
  return !matches ? (
    <AppLayoutSm>
      <AppLoaderWrapperProvider>
        <AppLoaderWrapper>
          <NotificationProvider>
            <Suspense fallback={null}>
              <Module />
            </Suspense>
          </NotificationProvider>
        </AppLoaderWrapper>
      </AppLoaderWrapperProvider>
    </AppLayoutSm>
  ) : (
    <AppLayout>
      <AppLoaderWrapperProvider>
        <AppLoaderWrapper>
          <NotificationProvider>
            <Suspense fallback={null}>
              <Module />
            </Suspense>
          </NotificationProvider>
        </AppLoaderWrapper>
      </AppLoaderWrapperProvider>
    </AppLayout>
  );
};

const AppRoutes = () => {
  // TO DO enable tracking after login setup
  // const { identifyUser } = useTracking();
  // create private route component
  return (
    <Router>
      <Routes>
        <Route path="/" element={withAppLayout(Home)} />
        <Route path="/home" element={withAppLayout(Home)} />
        <Route path="/deals" element={withAppLayout(Deals)} />
        <Route path="/news" element={withAppLayout(News)} />
        <Route path="/profile" element={withAppLayout(Profile)} />
        <Route path="/profile/investor/:profileId" element={withAppLayout(InvestorProfile)} />
        <Route path="/profile/startup" element={withAppLayout(StartupProfile)} />
        <Route path="/onboarding/:step?" element={<Onboarding />} />
        <Route path="/deck-analysis" element={withAppLayout(DeckUpload)} />
        <Route path="/deck-analysis/:deckId?" element={withAppLayout(DeckAnalysis)} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
