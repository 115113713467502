import { Button, ButtonProps } from '@chakra-ui/react';
import { forwardRef } from 'react';

export type CustomButtonProps = ButtonProps & {
  variant?: 'solid' | 'outline' | 'transparent-with-icon' | 'danger';
  color?: 'primary' | 'secondary';
  to?: string;
};

const ActionButton = forwardRef<HTMLButtonElement | HTMLAnchorElement, CustomButtonProps>(
  ({ children, variant = 'solid', color = 'primary', ...rest }, ref) => {
    let buttonStyles: ButtonProps = {};

    const baseStyles: ButtonProps = {
      _focus: { outline: 'none' },
    };

    switch (variant) {
      case 'solid':
        buttonStyles = {
          ...baseStyles,
          padding: '4px 8px',
          height: '32px',
          borderRadius: '2px',
          backgroundColor: color === 'primary' ? '#4285F4' : '#EFEFF3',
          color: color === 'primary' ? '#FFFFFF' : '#262B47',
          _hover: {
            backgroundColor: color === 'primary' ? '#356AC3' : '#CFD0D8',
          },
          _active: {
            backgroundColor: color === 'primary' ? '#285092' : '#B7BAC5',
          },
          _disabled: {
            backgroundColor: '#EFEFF3',
            color: '#A0A4B2',
            cursor: 'not-allowed',
          },
        };
        break;
      case 'outline':
        buttonStyles = {
          ...baseStyles,
          padding: '4px 8px',
          border: '1px',
          borderRadius: '2px',
          borderColor: color === 'primary' ? '#4285F4' : '#CFD0D8',
          color: color === 'primary' ? '#4285F4' : '#383D58',
          backgroundColor: '#FFFFFF',
          _hover: {
            color: color === 'primary' ? '#356AC3' : '#383D58',
            borderColor: color === 'primary' ? '#356AC3' : '#B7BAC5',
            backgroundColor: color === 'primary' ? '#ECF3FE' : '#EFEFF3',
          },
          _active: {
            color: color === 'primary' ? '#285092' : '#383D58',
            borderColor: color === 'primary' ? '#285092' : '#A0A4B2',
            backgroundColor: color === 'primary' ? '#D9E7FD' : '#CFD0D8',
          },
          _disabled: {
            color: '#B7BAC5',
            borderColor: '#CFD0D8',
            backgroundColor: '#FFFFFF',
            cursor: 'not-allowed',
          },
        };
        break;
      case 'transparent-with-icon':
        buttonStyles = {
          ...baseStyles,
          padding: '8px',
          color: color === 'primary' ? '#4285F4' : '#383D58',
          backgroundColor: 'transparent',
          _hover: {
            backgroundColor: 'transparent',
          },
          _active: {
            backgroundColor: 'transparent',
          },
          _disabled: {
            color: '#B7BAC5',
            cursor: 'not-allowed',
          },
        };
        break;
      case 'danger':
        buttonStyles = {
          ...baseStyles,
          padding: '4px 8px',
          height: '32px',
          borderRadius: '2px',
          backgroundColor: 'red.500',
          color: color === 'primary' ? '#FFFFFF' : '#262B47',
          _hover: {
            backgroundColor: 'red.600',
          },
        };
        break;
      default:
        break;
    }

    return (
      <Button {...buttonStyles} {...rest} ref={ref}>
        {children}
      </Button>
    );
  }
);
ActionButton.displayName = 'ActionButton';
export default ActionButton;
