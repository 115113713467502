import { Colors } from '@chakra-ui/react';

export const colors: Colors = {
  grey: {
    50: '#EFEFF3',
    100: '#F7F9FA',
    200: '#E9EDF1',
    300: '#D3DBE4',
    400: '#BDC9D6',
    500: '#90A4BA',
    600: '#6884A1',
    700: '#4C637B',
    800: '#334252',
    900: '#192129',
  },
  primary: {
    50: '#EDF1FA',
    100: '#C8D3F3',
    200: '#96ABE8',
    300: '#5F7FDD',
    400: '#2C55CE',
    500: '#21409A',
    600: '#1A327A',
    700: '#14265C',
    800: '#0E1A3F',
    900: '#060C1D',
  },
  secondary: {
    50: '#F7F7F7',
    100: '#EAEAEA',
    200: '#D7D7D7',
    300: '#BEBEBE',
    400: '#A3A3A3',
    500: '#8A8A8A',
    600: '#6E6E6E',
    700: '#515151',
    800: '#343434',
    900: '#171717',
  },
  red: {
    50: '#FFEBEB',
    100: '#FFD1D1',
    200: '#FFA8A8',
    300: '#FF7A7A',
    400: '#FF4D4D',
    500: '#FF2121',
    600: '#E60000',
    700: '#AD0000',
    800: '#750000',
    900: '#380000',
  },
  orange: {
    50: '#FFFAF0',
    100: '#FEEBCB',
    200: '#FBD38D',
    300: '#F6AD55',
    400: '#ED8936',
    500: '#DD6B20',
    600: '#C05621',
    700: '#9C4221',
    800: '#7B341E',
    900: '#652B19',
  },
  green: {
    50: '#E1FFE5',
    100: '#C2FFCB',
    200: '#85FE97',
    300: '#43FE5F',
    400: '#06FE2B',
    500: '#01C520',
    600: '#019D18',
    700: '#017512',
    800: '#00510D',
    900: '#002906',
  },
  // TO DO: Add full set of colors commented as causing issue of transparent background
  // white: {
  //   // 50: '#FFFFFF',
  // },
  purple: {
    50: '#A373EB',
  },
  yellow: {
    50: '#FFF8EB',
    100: '#FFF0D1',
    200: '#FFE0A3',
    300: '#FFD175',
    400: '#FFC247',
    500: '#FFB21C',
    600: '#E09600',
    700: '#A87000',
    800: '#704B00',
    900: '#382500',
  },
  blue: {
    50: '#F2FCFF',
    100: '#C7F0FF',
    200: '#94E2FF',
    300: '#5CD3FF',
    400: '#29C6FF',
    500: '#00AEEF',
    600: '#008EC2',
    700: '#00698F',
    800: '#004761',
    900: '#00222E',
  },
};
