import api from './api';

const getAllDecks = () => {
  return api.get(`pitch-decks`);
};

const getUploadSignedUrl = (persona = 'vc') => {
  return api.get(`pitch-decks/upload-url`, {
    params: {
      persona,
    },
  });
};

const analyzeDeck = (deckId: number) => {
  return api.post(`pitch-decks/${deckId}/analyze`);
};

const getDeckDownloadUrl = (deckId: any) => {
  return api.get(`pitch-decks/${deckId}/download-url`);
};

export { analyzeDeck, getAllDecks, getDeckDownloadUrl, getUploadSignedUrl };
