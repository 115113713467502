import { USER_DETAILS_CREATE } from 'pages/Onboarding/components/user-details-form';

import api from './api';

const getUser = () => {
  return api.get(`/users/me`);
};

const updateUser = (user: USER_DETAILS_CREATE) => {
  return api.patch(`/users`, {
    profile: user,
  });
};

export { getUser, updateUser };
